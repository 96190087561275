.fire-hamburger-icon {
  transition:  '.5s';
  fill: var(--primary-alt2);
  background-color: var(--white);
  border: var(--gray) 0.5px solid;
  @apply w-12;
  @apply h-12;
  @apply rounded-full;
  @apply p-2;  
  z-index: 5;
  position:  fixed;
}

.fire-hamburger-icon:hover {
  fill: var(--primary-color);  
  border-color: var(--dark-gray);
  @apply w-16;
  @apply h-16;
}

.navigation {
  transition: 0.5s;
  width: 250px;
  padding-top: 300px;
  left: -100%;
  position: fixed;
}

.navigation.open {
  left: 0;
}