.header-splash {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 90% 10%;
  perspective: 500px;
  width: 100vw;
  height: 100vh;
  max-width: 100%;
}

.header-splash-title {
  font-size: 4.5rem;
  font-family: 'Ubuntu', sans-serif;
  text-shadow: 1px 1px var(--dark-gray);
}

.panel {
  grid-row: 1 / 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.panel.left {
  grid-column: 1 / 3;  
  /* background-color: var(--secondary-color); */
  color: var(--white);
  padding-right: .75rem;
}

.panel.left::after {
  content: "";
  background-color: var(--secondary-color);
  /* border-bottom: 100px solid red;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  height: 0;
  width: 100px; */
  width: 50%; 
  height: 100%;
  position: absolute;
  z-index: -1;
  transform: rotate3d(1, 1, 1, 5deg);
}

.panel.left > div {
  text-align: right;
}

.panel.right {
  grid-column: 3 / 5;  
  padding-left: .75rem;
}

.see-more-container {
  grid-column: 4 / span 1;
  grid-row: 2 / 3;
}

.angle-down-icon {
  float: right;
  margin-right: 1.25rem;  
  width: 1.5rem;
  fill: var(--primary-alt1);

  animation: pulse;
  animation-duration: 0.75s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes pulse {
  from {opacity: 100%}
  to {opacity: 25%;}
}

.home-grid-container {
  align-items: center;
}
