.cell {
  flex: 1;
  text-align: center;
  height: 50px;
  padding-top: 10px;
  border: 1px  dotted  lightgray;
}

.animate-in {
  
}